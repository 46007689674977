import request from '@/utils/request'
export function bookListCategory(data) {
    return request({
        url: '/v2/culture/hcwhy.ebook/category',
        method: 'post',
        params: data
    })
}

export function bookList(data) {
    return request({
        url: '/v2/culture/hcwhy.ebook/lists',
        method: 'post',
        params: data
    })
}

