<template>
  <div class="resources-container">
    <div class="resources-content clear" v-loading="pageLoading">
      <div class="tab-secondNav" v-if="secondNav.length>0">
        <ul class="tab-secondNav-choose-item">
          <li
              v-for="(item, i) in secondNav"
              :key="i"
              :class="{ tabActivesecondNav: cur2 == item.id }"
              @click="changeSecondNav(item,i)"
          >
            {{ item.name }}
            <span :class="{tabActivesecondNavSpan: cur2 == item.id}">
                <span :class="{tabActivesecondNavSubSpan: cur2 == item.id}">

                </span>
            </span>
          </li>
        </ul>

      </div>
<!--      <div class="resources-switch-type">-->
<!--         <div class="resources-switch-type-title">类型：</div>-->
<!--        <div class="resources-switch-type-content">-->
<!--          <div-->
<!--              class="resources-switch-type-content-li"-->
<!--              :class="{-->
<!--              'resources-switch-type-content-li-active': current == -1,-->
<!--            }"-->
<!--              @click="handleClickSubCategory(-1, -1)"-->
<!--          >-->
<!--            全部-->
<!--          </div>-->
<!--          <div-->
<!--            class="resources-switch-type-content-li"-->
<!--            :class="{-->
<!--              'resources-switch-type-content-li-active': current == index,-->
<!--            }"-->
<!--            v-for="(item, index) in typeList"-->
<!--            :key="item.id"-->
<!--            @click="handleClickSubCategory(index, item)"-->
<!--          >-->
<!--            {{ item.category_name }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="resources-switch-type" v-if="subTypeList.length > 0">
         <div class="resources-switch-type-title"></div>
        <div class="resources-switch-type-content">
          <div
              class="resources-switch-type-content-li"
              :class="{
              'resources-switch-type-content-li-active': curr == -1,
            }"
              @click="handleClickCategory(-1, -1)"
          >
            全部
          </div>
          <div
            class="resources-switch-type-content-li"
            :class="{
              'resources-switch-type-content-li-active': curr == index,'imgType':item.category_name.length > 4
            }"
            v-for="(item, index) in subTypeList"
            :key="index"
            @click="handleClickCategory(index, item)"
          >
            {{ item.category_name }}
          </div>
        </div>
      </div>

      <!-- 最新·最热 -->
      <div class="resources-content-hot-new">
        <div class="resources-content-hot-new-icon">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/title_left_icon.png"
            alt=""
          />
        </div>
        <div
          class="resources-content-hot-new-title"
          @click="handleChangeValue2('sort', 0)"
        >
          <div
            :style="{
              'font-weight': cur == 'sort' ? 'bold' : '',
            }"
          >
            默认
          </div>
          <div
            class="resources-content-hot-new-title-bg"
            :style="{
              display: cur == 'sort' ? 'block' : 'none',
            }"
          ></div>
        </div>
        <div class="resources-content-new-mid-hot"></div>
        <div
          class="resources-content-hot-new-title"
          @click="handleChangeValue2('id', 1)"
        >
          <div
            :style="{
              'font-weight': cur == 'id' ? 'bold' : '',
            }"
          >
            最新
          </div>
          <div
            class="resources-content-hot-new-title-bg"
            :style="{
              display: cur == 'id' ? 'block' : 'none',
            }"
          ></div>
        </div>
        <div class="resources-content-new-mid-hot"></div>
        <div
          class="resources-content-hot-new-title resources-content-hot-new-title1"
          @click="handleChangeValue2('view_count', 2)"
        >
          <div :style="{ 'font-weight': cur == 'view_count' ? 'bold' : '' }">
            最热
          </div>
          <div
            class="resources-content-hot-new-title-bg"
            :style="{ display: cur == 'view_count' ? 'block' : 'none' }"
          ></div>
        </div>
        <div class="resources-content-hot-new-icon">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/title_right_icon.png"
            alt=""
          />
        </div>
      </div>


      <!-- 类型的tab切换中的假内容 -->
      <div
        class="resources-tab-content clear"
        v-if="active_tab == 0 && content.length > 0 && pc_template_status == 1"
      >



        <listItem
          v-if="class_id==2"
          listType="resources_t"
          :list="content"
          v-loading="listLoading"
          :class_id="class_id"
          :currentName="currentName"
        ></listItem>

        <listItem
          v-else
          listType="resources_e"
          :list="content"
          v-loading="listLoading"
          :class_id="class_id"
          :currentName="currentName"
          :navId="navId"
          :cat_id="cat_id"
        ></listItem>
      </div>
      <div
        class="resources-tab-content clear"
        v-if="active_tab == 0 && content.length > 0 && pc_template_status == 2"
      >

        <commonRList
          :list="content"
          :class_id="class_id"
          :currentName="currentName"
        ></commonRList>


      </div>

      <!-- 电子资源库的类型为文学小说时的布局 1=视频，2=音频，3=电子书-->

      <!-- <div
        class="fiction clear"
        v-loading="listLoading"
        v-if="active_tab == 2 && content.length > 0"
      >
        <listItem
          listType="resources_t"
          :list="content"
          v-loading="listLoading"
        ></listItem>
      </div> -->


        <!-- 分页组件 -->
        <pagination
          :total="total"
          :page.sync="page"
          :limit.sync="bookLimit"
          @pagination="getResourceList"
        />
      <!-- 没有内容显示的页面 -->
      <Nocontent
        v-if="content.length == 0"
        title="暂无数据，请持续关注哦～"
      ></Nocontent>
    </div>
  </div>
</template>

<script>
import { queryResourceChildCategory, queryResourceList } from "@/api/common";
import { bookListCategory, bookList } from "../../api/resources/resources";
import commonRList from "../components/commonRlist";
import Pagination from "@/components/Pagination";
import ListItem from "@/views/components/listItem";
import Nocontent from "@/views/components/Nocontent";
import {navChildList} from "@/api/header/header";

export default {
  data() {
    return {
      pageLoading: false,
      listLoading: true,
      total: 0,
      page: 1,
      limit: 6,
      bookLimit: 6,
      curr: -1,
      current: -1,
      order: "sort",
      currentType: 0,
      typeList: [],
      subTypeList: [],
      aid: process.env.VUE_APP_AID,
      elcTypeList: [],
      content: [],
      subCategory: "",
      cat_id: "",
      active_tab: 0,
      cur: "sort",
      isSelected: true,
      firstId: "",
      firstCatId: "",
      getChild: 1,
      class_id: this.$route.query.id,

      pc_template_status:1,   //1为图文 2为列表
      secondNav:[],
      cur2:0,
      navId:this.$route.query.navId
    };
  },
  components: {
    Pagination,
    ListItem,
    Nocontent,
    commonRList
  },
  props: {
    nav: {
      type: Array,
      default: null,
    },
  },
  watch: {
    // isSelected(newV, oldV) {
    //   console.log(newV, oldV);
    //   if (newV) {
    //     this.handleClickSubCategory(0, this.firstCatId);
    //   } else {
    //     this.handleClickChildCategory(0, this.firstId);
    //   }
    // },
    $route() {
      this.cat_id = this.$route.query.cat_id;
      this.class_id = this.$route.query.id;
      // alert(this.class_id)
      // this.getChildNav()
    },
    cat_id(newV, oldV) {
      this.cat_id = newV;
      this.getSubCategory(this.class_id);
      this.navId = this.$route.query.navId;
      this.getChildNav();
      this.getResourceList();

        console.log(this.nav)
      var myCurrentNavId = this.getQueryVariable(window.location.href,'navId')
      this.nav.forEach((res) => {
      if(res.navId == myCurrentNavId) {
        document.title  = res.name + "-荆门市群众艺术馆"
      }
    })
    },

  },
  mounted() {
    console.log(this.nav)
    var myCurrentNavId = this.getQueryVariable(window.location.href,'navId')
    this.nav.forEach((res) => {
      if(res.navId == myCurrentNavId) {
        // alert(res.name)
        
        document.title  = res.name + "-荆门市群众艺术馆"
      }
    })
    this.getSubCategory(this.class_id);
    // this.getChildNav()
    this.cat_id = this.$route.query.cat_id
      ? this.$route.query.cat_id
      : this.$route.query.cat_id;
      // document.title = 'xxxx'
  },
  methods: {
    getQueryVariable(url,variable) {
            var query = url.split("?")[1]; // 根据“?”切割数组，截取“?”之后的字符串
            // ['https://qq.com','appid=1234567890&name=Zdf']
            var vars = query.split("&"); // 根据“&”切割成数组
            // ['appid=1234567890','name=Zdf']
            for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    // ['appid','1234567890']
                    if(pair[0] == variable) return pair[1]; // 没有花括号默认执行第一个js语句
            }
            return(false);
    },
    changeSecondNav(item) {
      sessionStorage.setItem('secondSelectId',item.id)
      console.log('resourceNav')
      console.log(item)
      this.currentName = item.name
      this.curr = -1;
      if(item.url.indexOf("http")!=-1){
        window.open(item.url);
        }else{
          if(item.url.indexOf("navId")==-1){
        item.url +="&navId="+this.navId;
      }
      
      else{
        this.$router.push({
          path: item.url,
        });
      }
        }
      


    },
    getChildNav() {
      let params = {
        aid: this.aid,
        pid: this.navId,
        app_type: 1,
      };  navChildList(params).then((res) => {
        console.log(res)
        this.secondNav = res.data
        if (res.code == 0) {
          this.secondNav = res.data
          var data = res.data;
          this.secondNav = data;
          console.log(this.secondNav)
          var status = false
          data.forEach((item,index) => {
            if (item.name == this.$route.query.name) {
              this.cur2 = item.id;
              status = true
              this.currentName = item.name
            }else if(!status && item.id == sessionStorage.getItem('secondSelectId')) {
              this.cur2 = item.id;
              this.currentName = item.name
            }
            
          });
        }
      });
    },
    handleClickCategory(index, item) {
      this.page = 1;
      this.curr = index;
      if(index == -1){
        this.cat_id = this.$route.query.cat_id;
      }else{
        this.cat_id = item.id;
      }

      this.getResourceList();
    },
    handleClickSubCategory(i, item) {
     this.curr = -1;
      this.page = 1;
      this.active_tab = 0;
      this.current = i;
      this.cat_id = item.children_data
        ? item.children_data[0].id
        : item.id;
      this.subTypeList = item.children_data ? item.children_data : [];
      this.page = 1;
      // this.getResourceList();
    },
    getSubCategory(class_id) {
      queryResourceChildCategory({ aid: this.aid, class_id }).then((res) => {
        this.typeList = res.data;
        console.log(this.typeList)
        res.data.forEach((item, index) => {
          if (item.id == this.cat_id) {
            this.subTypeList = item.children_data;
            // this.subTypeList.forEach(items=>{
            //   if(items.category_name.length > 4) {
            //     items.backImgTypeClass =  
            //   }
            // })
            
          }
        });

        // this.cat_id = res.data[this.current].children_data[this.curr].id;
        this.getResourceList();
      });
    },
    getSubCategoryElc(class_id) {
      bookListCategory({ aid: this.aid, class_id }).then((res) => {
        this.elcTypeList = res.data;
        this.firstId = res.data[0].book_cat_id;
      });
    },
    getResourceList() {
      this.content = [];
      let self = this;
      self.listLoading == true;
      if(this.class_id == 2) {
        this.limit = 10
        this.bookLimit = 10
      }else{
        this.bookLimit = 6
      }
      let params = {
        aid: this.aid,
        page: this.page,
        limit: this.limit,
        category_id: this.cat_id,
        sort_field: this.order,
        get_children_list: this.getChild,
      };
      if (this.active_tab == 0) {
        queryResourceList(params).then((res) => {
        
            var data = res.data
          if (res.code == 0) {
            if(data.templates['pc_template_code'] == 'title_list') {
              this.pc_template_status = 2
            }else{
              this.pc_template_status = 1
            }


            this.content = res.data.datalist;
            //总分页数
            // var pageCount = (this.limit + res.data.count - 1)/res.data.count;
           
            // alert(total)
            this.total = res.data.count;
            
          }
          setTimeout(function() {
            self.listLoading = false;
          }, 1000);
        });
      } else if (this.active_tab == 2) {
        let params = {
          aid: self.aid,
          page: self.page,
          limit: self.bookLimit,
          category_id: self.cat_id,
          sort_field: self.order,
          get_children_list: self.getChild,
        };
        bookList(params).then((res) => {
          this.content = res.data.rows;
          this.listLoading = false;
          this.total = res.data.total;
        });
      }
    },
    handleChangeValue2(cur, i) {
      this.cur = cur;
      let order = "";
      switch (i) {
        case 0:
          order = "sort";
          break;
        case 1:
          order = "id";
          break;
        case 2:
          order = "view_count";
          break;
      }
      this.order = order;
      this.getResourceList();
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/resources.scss";
@import "../../assets/scss/header_common.scss";
.tab-secondNav-choose-item{
  font-size: 0.17rem;
  border-bottom: 1px solid #D5D5D5;
  padding-bottom: 0.14rem;
}
.tab-secondNav-choose-item{
  display: flex;
}
.tab-secondNav-choose-item li{
  margin-left: 10px;
}
.tab-secondNav-choose-item li{
  cursor: pointer;
}
.tab-secondNav-choose-item li:not(:first-child){
  cursor: pointer;
  margin-left: 0.46rem;
}
.tabActivesecondNav{
  color: #761F1E;
  position: relative;
}

.tabActivesecondNavSpan{
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: -0.14rem;
  left: 0rem;
}
.tabActivesecondNavSubSpan{
  width: 80%;
  background: #991C10;
  height: 3px;
  display: inline-block;
  position: absolute;
  animation: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}
.tab-subSwitch{
  margin-top: 0.33rem;
}
.news-container {
  padding-top: 0.38rem;
}
.resources-ul-li-bottom-title {
    width: 100%;
    height: 0.76rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #273143;
    line-height: 0.36rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 0.11rem;

}
li{
  overflow: visible;
}
</style>
